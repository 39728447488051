<template>
  <div class="cs-container" style="background-color: white;">
    <div class="d-print-none mb-2 flex items-center justify-end" style="margin-right:80px">
      <b-button variant="primary" @click="handlePrint">In Phiếu</b-button>
    </div>

    <header-form></header-form>

    <div class="d-flex align-items-center justify-content-center">
      <span class="close-icon d-print-none" @click="backToDetail()">&times;</span>
    </div>
    <div id="printOrderForm">
      <h2 class="title-color">PHIẾU ĐỒNG THUẬN THỰC HIỆN XÉT NGHIỆM G4500</h2>
      <b-row>
        <div class="cs-width-full">
          <div class="cs-width-full">
            <span class="cs-text-sm cs-border-b cs-flex">PHẦN 1. THÔNG TIN CỦA NGƯỜI LÀM XÉT NGHIỆM</span>
          </div>
        </div>
        <div class="cs-width-full">
          <div class="cs-width-full container-border-2 flex flex-row">
            <span class="cs-flex col-6">
              Họ tên (IN HOA):
              <span class="cs-content" v-html="form.full_name"></span>
            </span>
            <span class="cs-flex col-3">
              Ngày sinh:
              <!-- <span class="cs-content"  v-html="form.birthday">123</span> -->
              <span class="cs-content">{{ handleFormatDate(form.birthday) }}</span>
            </span>
            <span class="cs-flex col-3">
              Giới tính:
              <div class="d-flex cs-content">
                <div class="mr-1 cs-checkbox" :set="isMale=form.gender ===1">
                  <input
                    v-model="isMale"
                    id="female"
                    name="female"
                    type="checkbox"
                    @change="(e)=>e.target.checked ? form.gender = 1 : form.gender = null"
                  />
                  <label>Nam</label>
                </div>
                <div class="mr-1 cs-checkbox" :set="isFemale= form.gender===2">
                  <input
                    v-model="isFemale"
                    id="female"
                    name="female"
                    type="checkbox"
                    @change="(e)=>e.target.checked ? form.gender = 2 : form.gender = null"
                  />
                  <label>Nữ</label>
                </div>
              </div>
            </span>
          </div>
          <div class="cs-width-full container-border-2 flex flex-row">
            <span class="cs-flex col-6">
              Địa chỉ:
              <span class="cs-content" v-html="form.patient_address"></span>
            </span>
            <span class="cs-flex col-3"></span>
            <span class="cs-flex col-3">
              Điện thoại:
              <span class="cs-content" v-html="form.patient_phone"></span>
            </span>
          </div>
          <div class="cs-width-full container-border-2 flex flex-row">
            <span class="cs-flex col-3">
              Bác sĩ:
              <span class="cs-content" v-html="form.doctor"></span>
            </span>
            <span class="cs-flex col-3">
              PK/BV:
              <span class="cs-content" v-html="form.hospital"></span>
            </span>
            <span class="cs-flex col-3">
              Điện thoại:
              <span class="cs-content" v-html="form.doctor_phone"></span>
            </span>
            <span class="cs-flex col-3">
              Email:
              <span class="cs-content" v-html="form.doctor_email"></span>
            </span>
          </div>
          <div class="cs-width-full container-border-2 flex flex-row">
            <span class="cs-flex col-3">
              Ngày lấy mẫu:
              <span
                class="cs-content"
              >{{ handleFormatDate(form.sample_collection_date_time) }}</span>
            </span>
            <span class="cs-flex col-3"></span>
            <span class="cs-flex col-3"></span>
            <span class="cs-flex col-3">
              Mã XN:
              <span class="cs-content" v-html="form.factory_code"></span>
            </span>
          </div>
        </div>

        <div class="cs-width-full">
          <span class="cs-text-sm cs-flex">PHẦN 2. LỰA CHỌN XÉT NGHIỆM</span>
        </div>
        <div class="cs-width-full">
          <div class="cs-width-full container-border flex flex-col">
            
            <div class="cs-flex cs-content">
              <div class="mr-1 cs-checkbox" :set="isG4500=form.choice===1">
                <input
                  v-model="isG4500"
                  id="g4500"
                  name="g4500"
                  type="checkbox"
                  @change="(e)=>e.target.checked ? form.choice = 1 : form.choice = null"
                />
                <label>G4500</label>
              </div>
              <div class="mr-1 cs-checkbox" :set="isG4500Family= form.choice===2">
                <input
                  v-model="isG4500Family"
                  id="g4500-family"
                  name="g4500-family"
                  type="checkbox"
                  @change="(e)=>e.target.checked ? form.choice = 2 : form.choice = null"
                />
                <label>G4500 Family(Bệnh nhân và 2 người thân)</label>
              </div>
            </div>

            <div class="cs-width-full flex flex-col">
              <span class="cs-flex mb-6">
                Dạng mẫu:
                <div class="d-flex cs-content">
                  <div class="mr-1 cs-checkbox" :set="isBlood=form.sample1===1">
                    <input
                      v-model="isBlood"
                      id="blood"
                      name="blood"
                      type="checkbox"
                      @change="(e)=>e.target.checked ? form.sample1 = 1 : form.sample1 = null"
                    />
                    <label>Máu</label>
                  </div>
                  <div class="mr-1 cs-checkbox" :set="isSlime= form.sample1===2">
                    <input
                      v-model="isSlime"
                      id="slime"
                      name="slime"
                      type="checkbox"
                      @change="(e)=>e.target.checked ? form.sample1 = 2 : form.sample1 = null"
                    />
                    <label>Dịch ối</label>
                  </div>
                  <div class="mr-1 cs-checkbox" :set="isStomach= form.sample1===3">
                    <input
                      v-model="isStomach"
                      id="stomach"
                      name="stomach"
                      type="checkbox"
                      @change="(e)=>e.target.checked ? form.sample1 = 3 : form.sample1 = null"
                    />
                    <label>phết niêm mạc</label>
                  </div>
                </div>
              </span>
            </div>
            <div class="cs-width-full flex flex-col">
              <span class="cs-flex col-3 border-b-4 border-indigo-500">* Nếu làm G4500 Family:</span>
            </div>
            <div class="d-flex cs-border-t">
              <div class="col-6 p-0 cs-border-r">
                <div class="cs-flex cs-border-b">
                  <span class="cs-text-md">THÔNG TIN NGƯỜI THÂN THỨ 1</span>
                </div>
                <div class="d-flex flex-column">
                  <div class="cs-width-full cs-border-b cs-flex">
                    Họ tên:
                    <span class="cs-content" v-html="form.members_name_one"></span>
                  </div>
                  <div class="cs-width-full cs-border-b cs-flex">
                    Ngày sinh:
                    <span class="cs-content">{{ handleFormatDate(form.birth_date_one) }}</span>
                  </div>
                  <div class="cs-width-full cs-border-b cs-flex">
                    Mối quan hệ với bệnh nhân:
                    <span
                      class="cs-content"
                      v-html="form.relationship_one"
                    ></span>
                  </div>
                  <div class="cs-width-full cs-flex" style="margin-bottom: 60px">
                    Mô tả kiểu hình:
                    <span class="cs-content" v-html="form.appearance_one"></span>
                  </div>
                </div>
              </div>
              <div class="col-6 p-0">
                <div class="cs-flex cs-border-b">
                  <span class="cs-text-md">THÔNG TIN NGƯỜI THÂN THỨ 2</span>
                  <span class="cs-content ml-1" v-html="form.counsellor_name"></span>
                </div>
                <div class="d-flex flex-column align-items-start cs-width-full">
                  <div class="cs-width-full cs-border-b cs-flex">
                    Họ tên:
                    <span class="cs-content" v-html="form.members_name_two"></span>
                  </div>
                  <div class="cs-width-full cs-border-b cs-flex">
                    Ngày sinh:
                    <span class="cs-content">{{ handleFormatDate(form.birth_date_two) }}</span>
                  </div>
                  <div class="cs-width-full cs-border-b cs-flex">
                    Mối quan hệ với bệnh nhân:
                    <span
                      class="cs-content"
                      v-html="form.relationship_two"
                    ></span>
                  </div>
                  <div class="cs-width-full cs-flex">
                    Mô tả kiểu hình:
                    <span class="cs-content" v-html="form.appearance_two"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="cs-width-full">
          <div class="cs-width-full container-border none-border-bottom">
            <span class="cs-text-sm cs-flex">PHẦN 3. THỎA THUẬN SỬ DỤNG DỊCH VỤ</span>
            <div
              class="cs-flex cs-content pb-0"
            >Những điểm quan trọng người tham gia xét nghiệm (hoặc người giám hộ) cần biết trước khi đồng ý tham gia thực hiện G4500
            </div>
            <div class="cs-width-full flex flex-col">
              <span class="cs-flex col-3">
                <strong>A. MỤC ĐÍCH CỦA XÉT NGHIỆM:</strong>
              </span>
              <div class="d-flex cs-content ml-1">
                Xét nghiệm được thiết kế để phát hiện các biến thể di truyền liên quan đến triệu chứng lâm sàng của người tham gia xét nghiệm thông qua việc giải trình tự đồng thời tất cả các gen gây bệnh được biết đến hiện nay(4503 gen), đồng thời khảo sát lệch bội của 24 NST và tất cả vi mất đoạn, vi lặp đoạn có kích thước > 400kb
                <br />Xét nghiệm thích hợp cho các đối tượng:
              </div>
            </div>
            <div>
              <span class="cs-flex">
                <strong>1. Nghi ngờ có rối loạn về di truyền, nhưng chưa biết rõ gen gây bệnh</strong>
              </span>
              <span class="cs-flex">
                <strong>2. Có các rối loạn di truyền phức tạp, không điển hình, dẫn đến nhiều chẩn đoán khác nhau</strong>
              </span>
              <span class="cs-flex">
                <strong>3. Có các biểu hiện bệnh không đồng nhất về mặt di truyền</strong>
              </span>
            </div>
            <div class="cs-width-full flex flex-col">
              <span class="cs-flex col-3">
                <strong>B. MỤC ĐÍCH CỦA XÉT NGHIỆM:</strong>
              </span>
            </div>
            <div>
              <span class="cs-flex">
                <strong>1. Việc tham gia giải trình tự gen là hoàn toàn tự nguyện</strong>
              </span>
              <span class="cs-flex">
                <strong>2. Chỉ 20% số ca thực hiện là tìm được nguyên nhân gây bệnh, tỉ lệ này tăng lên 45% nếu cha và mẹ của bệnh nhi cùng thực hiện xét nghiệm di truyền này</strong>
              </span>
              <span class="cs-flex">
                <strong>3. Kết quả có thể cung cấp những thông tin không mong đợi như:</strong>
              </span>
              <ol class="order-list">
                <li>Phát hiện những biến thể gây bệnh không liên quan đến triệu chứng lâm sàng. Trong trường hợp này, các biến thể gây bệnh trên 59 gen gây ung thư hoặc các bệnh lý tim mạch (được đề cập bởi Hiệp hội Di truyền y học Hoa Kỳ (ACMG)) sẽ được thông báo trong phần trả kết quả.</li>
                <li>Phát hiện những biến thể gây bệnh ở dạng dị hợp tử (thể mang - carrier)</li>
              </ol>
            </div>
            <div class="cs-width-full flex flex-col">
              <span class="cs-flex col-3">
                <strong>C. HẠN CHẾ CỦA XÉT NGHIỆM:</strong>
              </span>
            </div>
            <div class="cs-flex">
              <span>1. Xét nghiệm chỉ khảo sát các vùng mã hóa protein(exon, mang 85% tổng số các biến thể gây bệnh) của các gen mục tiêu và phát hiện các đột biến điểm, các mất đoạn/lặp đoạn nhỏ(kích thước không quá 20bp).</span>
            </div>
            <div class="cs-flex">
              <span>2. Xét nghiệm không có khả năng phát hiện các biến thể di truyền nằm ngoài vùng mã hóa, những đoạn lặp liên tục(nucleotide repeat expansion), vùng giàu CG, vùng có trình tự tương đồng cao (giả gen - pseudogenes) và các biến thể dạng khảm. Ví dụ về những bệnh không thể phát hiện bằng xét nghiệm này bao gồm: bệnh Huntington, bệnh loạn dưỡng cơ(Duchenne/Becker), hội chứng fragile X, thoái hóa tiểu não (spinocerebella ataxias), bệnh Charcot-Marie-Tooth tuýp 1A, neural pressure palsy, SMA... Xét nghiệm cũng không khảo sát các gen trong ti thể.</span>
            </div>
            <div class="cs-flex">
              <span>3. Chỉ những biến thể di truyền liên quan đến triệu chứng lâm sàng của người tham gia xét nghiệm mới được thông báo. Vì vậy, thông tin lâm sàng không chính xác hoặc không đầy đủ có thể ảnh hưởng đến việc diễn giải kết quả di truyền</span>
            </div>
            <div class="cs-flex">
              <span>4. Những biến thể không liên quan đến triệu chứng lâm sàng của người thực hiện xét nghiệm sẽ không được thông báo trong bảng trả kết quả (ngoại trừ các biến thể gây bệnh trên 59 gen được đề cử bởi ACMG)</span>
            </div>
          </div>
          <div class="html2pdf__page-break"></div>
        </div>

        <div class="cs-width-full">
          <div class="container-border cs-width-full none-border-bottom">
            <div class="cs-width-full flex flex-col">
              <span class="cs-flex col-3">
                <strong>E. NGHIÊN CỨU:</strong>
              </span>

              <span
                class="cs-flex-2"
              >Chúng tôi có thể sử dụng mẫu dư và các thông tin đã bị loại bỏ danh tính của người làm xét nghiệm vào mục đích nghiên cứu. Các nghiên cứu này có thể được công bố trên các tạp chí khoa học hoặc sử dụng để phát triển các sản phẩm và dịch vụ của chúng tôi. Người làm xét nghiệm sẽ không được thông báo hoặc trả tiền cho các hoạt động nghiên cứu này.</span>
            </div>
            <span class="cs-text-sm cs-border-b part-4 cs-flex">PHẦN 4. THÔNG TIN LÂM SÀNG</span>
            <div class="d-flex">
              <div class="col-6 p-0 cs-border-r">
                <div class="cs-flex flex flex-row cs-border-b">
                  <span class="cs-text-md">
                    Bệnh nhân hoặc thành viên trong gia đình có ghép tủy?
                    <div class="d-flex cs-content" style="margin: auto">
                      <div class="cs-checkbox box" :set="isSpine=form.spine===1">
                        <input
                          v-model="isSpine"
                          id="spine"
                          name="spine"
                          type="checkbox"
                          @change="(e)=>e.target.checked ? form.spine = 1 : form.spine = null"
                        />
                        <label>Không -</label>
                      </div>
                      <div class="cs-checkbox box" :set="isNotSpine=form.spine===2">
                        <input
                          v-model="isNotSpine"
                          id="not-spine"
                          name="not-spine"
                          type="checkbox"
                          @change="(e)=>e.target.checked ? form.spine = 2 : form.spine = null"
                        />
                        <label>Có, người được ghép tủy................</label>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div class="col-6 p-0">
                <div class="cs-flex cs-border-b">
                  <span class="cs-text-md">
                    Bệnh nhân hoặc thành viên trong gia đình có truyền máu trong vòng 2 tuần?
                    <div class="d-flex cs-content" style="margin: auto">
                      <div class="cs-checkbox box" :set="isTransferred=form.blood_transfer===1">
                        <input
                          v-model="isTransferred"
                          id="spine"
                          name="spine"
                          type="checkbox"
                          @change="(e)=>e.target.checked ? form.blood_transfer = 1 : form.blood_transfer = null"
                        />
                        <label>Không -</label>
                      </div>
                      <div class="cs-checkbox box" :set="isNotTransferred=form.blood_transfer===2">
                        <input
                          v-model="isNotTransferred"
                          id="not-spine"
                          name="not-spine"
                          type="checkbox"
                          @change="(e)=>e.target.checked ? form.blood_transfer = 2 : form.blood_transfer = null"
                        />
                        <label>Có, người được truyền máu................</label>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="cs-flex left">
              <div>
                <span>
                  <strong>Mô tả tất cả triệu chứng lâm sàng:</strong>
                  <i>(kết quả phân tích dựa trên thông tin lâm sàng được cung cấp, vì vậy càng nhiều thông tin về lâm sàng sẽ tăng khả năng tìm ra nguyên nhân bệnh)</i>
                </span>
              </div>
            </div>
            <div class="d-flex cs-content">
              <div class="cs-checkbox box" :set="isMoron= form.one_moron ===1">
                <input
                  v-model="isMoron"
                  id="moron"
                  name="moron"
                  type="checkbox"
                  @change="(e)=>e.target.checked ? form.one_moron = 1 : form.one_moron = null"
                />
                <label>
                  <strong>Thiểu năng trí tuệ(giá trị IQ) - Tự kỷ:</strong>
                </label>
              </div>
            </div>
            <div class="d-flex cs-content">
              <div class="cs-checkbox box" :set="isNerveMuscle= form.two_nerve_muscles ===2">
                <input
                  v-model="isNerveMuscle"
                  id="never-muscle"
                  name="never-muscle"
                  type="checkbox"
                  @change="(e)=>e.target.checked ? form.two_nerve_muscles = 2 : form.two_nerve_muscles = null"
                />
                <label>
                  <strong>Thần kinh cơ:</strong>
                </label>
              </div>
            </div>
            <div class="d-flex cs-content">
              <div class="cs-checkbox box" :set="isThree= form.three === 3">
                <input
                  v-model="isThree"
                  id="abnormal-shape"
                  name="abnormal-shape"
                  type="checkbox"
                  @change="(e)=>e.target.checked ? form.three = 3 : form.three = null"
                />
                <label>
                  <strong>Bất thường kiểu hình - Tăng trưởng:</strong>
                </label>
              </div>
            </div>
            <div class="d-flex cs-content">
              <div class="cs-checkbox box" :set="isFour= form.four === 4">
                <input
                  v-model="isFour"
                  id="four"
                  name="four"
                  type="checkbox"
                  @change="(e)=>e.target.checked ? form.four = 4 : form.four = null"
                />
                <label>
                  <strong>Bất thường hộp sọ - Khung xương:</strong>
                </label>
              </div>
            </div>
            <div class="d-flex cs-content">
              <div class="cs-checkbox box" :set="isFive= form.five === 5">
                <input
                  v-model="isFive"
                  id="five"
                  name="five"
                  type="checkbox"
                  @change="(e)=>e.target.checked ? form.five = 5 : form.five = null"
                />
                <label>
                  <strong>Tim mạch:</strong>
                </label>
              </div>
            </div>
            <div class="d-flex cs-content">
              <div class="cs-checkbox box" :set="isSix= form.six === 6">
                <input
                  v-model="isSix"
                  id="six"
                  name="six"
                  type="checkbox"
                  @change="(e)=>e.target.checked ? form.six = 6 : form.six = null"
                />
                <label>
                  <strong>Thận - Tiết niệu:</strong>
                </label>
              </div>
            </div>
            <div class="d-flex cs-content">
              <div class="cs-checkbox box" :set="isSeven= form.seven === 7">
                <input
                  v-model="isSeven"
                  id="front-cancer"
                  name="front-cancer"
                  type="checkbox"
                  @change="(e)=>e.target.checked ? form.seven = 7 : form.seven = null"
                />
                <label>
                  <strong>Sinh dục:</strong>
                </label>
              </div>
            </div>
            <div class="d-flex cs-content">
              <div class="cs-checkbox box" :set="isEight= form.eight === 8">
                <input
                  v-model="isEight"
                  id="eight"
                  name="eight"
                  type="checkbox"
                  @change="(e)=>e.target.checked ? form.eight = 8 : form.eight = null"
                />
                <label>
                  <strong>Mắt:</strong>
                </label>
              </div>
            </div>
            <div class="d-flex cs-content">
              <div class="cs-checkbox box" :set="isNine= form.nine === 9">
                <input
                  v-model="isNine"
                  id="nine"
                  name="nine"
                  type="checkbox"
                  @change="(e)=>e.target.checked ? form.nine = 9 : form.nine = null"
                />
                <label>
                  <strong>Tai:</strong>
                </label>
              </div>
            </div>
            <div class="d-flex cs-content">
              <div class="cs-checkbox box" :set="isTen= form.ten === 10">
                <input
                  v-model="isTen"
                  id="ten"
                  name="ten"
                  type="checkbox"
                  @change="(e)=>e.target.checked ? form.ten = 10 : form.ten = null"
                />
                <label>
                  <strong>Miễn dịch:</strong>
                </label>
              </div>
            </div>
            <div class="d-flex cs-content">
              <div class="cs-checkbox box" :set="isEleventh= form.eleventh === 11">
                <input
                  v-model="isEleventh"
                  id="eleventh"
                  name="eleventh"
                  type="checkbox"
                  @change="(e)=>e.target.checked ? form.eleventh = 11 : form.eleventh = null"
                />
                <label>
                  <strong>Da liễu:</strong>
                </label>
              </div>
            </div>
            <div class="d-flex cs-content">
              <div class="cs-checkbox box" :set="isTwelve= form.twelve === 12">
                <input
                  v-model="isTwelve"
                  id="twelve"
                  name="twelve"
                  type="checkbox"
                  @change="(e)=>e.target.checked ? form.twelve = 12 : form.twelve = null"
                />
                <label>
                  <strong>Trao đổi chất:</strong>
                </label>
              </div>
            </div>
            <div class="d-flex cs-content">
              <div class="cs-checkbox box" :set="isThirteen= form.thirteen === 13">
                <input
                  v-model="isThirteen"
                  id="thirteen"
                  name="thirteen"
                  type="checkbox"
                  @change="(e)=>e.target.checked ? form.thirteen = 13 : form.thirteen = null"
                />
                <label>
                  <strong>Phổi:</strong>
                </label>
              </div>
            </div>
            <div class="d-flex cs-content">
              <div class="cs-checkbox box" :set="isFourteen= form.fourteen === 14">
                <input
                  v-model="isFourteen"
                  id="fourteen"
                  name="fourteen"
                  type="checkbox"
                  @change="(e)=>e.target.checked ? form.fourteen = 14 : form.fourteen = null"
                />
                <label>
                  <strong>Tiêu hóa:</strong>
                </label>
              </div>
            </div>
            <div class="d-flex cs-content">
              <div class="cs-checkbox box" :set="isFifteen= form.fifteen === 15">
                <input
                  v-model="isFifteen"
                  id="fifteen"
                  name="fifteen"
                  type="checkbox"
                  @change="(e)=>e.target.checked ? form.fifteen = 15 : form.fifteen = null"
                />
                <label>
                  <strong>Máu:</strong>
                </label>
              </div>
            </div>
            <div class="d-flex cs-content">
              <div class="cs-checkbox box" :set="isSixteen= form.sixteen === 16">
                <input
                  v-model="isSixteen"
                  id="sixteen"
                  name="sixteen"
                  type="checkbox"
                  @change="(e)=>e.target.checked ? form.sixteen = 16 : form.sixteen = null"
                />
                <label>
                  <strong>Ung thư:</strong>
                </label>
              </div>
            </div>
            <div class="d-flex cs-content">
              <div class="cs-checkbox box" :set="isSeventeen= form.seventeen === 17">
                <input
                  v-model="isSeventeen"
                  id="seventeen"
                  name="seventeen"
                  type="checkbox"
                  @change="(e)=>e.target.checked ? form.seventeen = 17 : form.seventeen = null"
                />
                <label>
                  <strong>Khác:</strong>
                </label>
              </div>
            </div>
            <div class="d-flex cs-content">
              <div class="cs-checkbox box" :set="isEighteen= form.eighteen === 18">
                <input
                  v-model="isEighteen"
                  id="eighteen"
                  name="eighteen"
                  type="checkbox"
                  @change="(e)=>e.target.checked ? form.eighteen = 18 : form.eighteen = null"
                />
                <label>
                  <strong>Không rõ:</strong>
                </label>
              </div>
            </div>
          </div>
          <div class="cs-width-full container-border none-border-bottom">
            <div class="cs-flex flex-row">
              <div>
                <span>Tiền căn gia đình (Nếu bệnh nhân có tiền sử gia đình mắc bệnh di truyền, xin hãy liệt kê và miêu tả dưới đây theo trình tự: tên - mối quan hệ - tuổi - mắc bệnh)</span>
              </div>
            </div>
            <div class="cs-flex flex-row" style="margin-top: 30px"></div>
          </div>
          <div class="cs-width-full container-border none-border-bottom">
            <div class="cs-flex flex-row">
              <div>
                <span>
                  Bệnh nhân có thực hiện xét nghiệm di truyền trước đây?
                  <br />Nếu có, xin gửi kèm và/hoặc mô tả dưới đây:
                </span>
              </div>
            </div>
            <div class="cs-flex flex-row" style="margin-top: 30px"></div>
          </div>
          <div class="cs-width-full container-border">
            <div class="cs-flex flex-row">
              <div>
                <span>Các chẩn đoán nghi ngờ (nếu có):</span>
              </div>
            </div>
            <div class="cs-flex flex-row" style="margin-top: 15px"></div>
          </div>
        </div>
        <div class="cs-width-full">
          <div class="container-border cs-width-full none-border-bottom">
            <span
              class="cs-text-sm cs-border-b cs-flex"
            >PHẦN 5. ĐỒNG Ý CỦA NGƯỜI LÀM XÉT NGHIỆM VÀ BÁC SĨ</span>
            <div class="d-flex">
              <div class="col-6 p-0 cs-border-r">
                <div class="cs-flex cs-border-b">
                  <span
                    class="cs-text-md"
                  >Tôi ủy quyền cho PXN Di truyền y học thực hiện giải trình tự gen trên mẫu của tôi (hoặc con tôi). Những rủi ro, lợi ích và những hạn chế của dịch vụ đã được giải thích đầy đủ.</span>
                </div>
                <div class="cs-flex flex-column align-items-start">
                  <div class="cs-text-sm" style="min-height:160px"></div>
                  <div style="min-height:60px">
                    <span>
                      * Xét nghiệm di truyền đối với trẻ em dưới 18 tuổi phải có sự đồng ý của cha mẹ hoặc người giám hộ hợp pháp. Nếu người giám hộ hợp pháp, vui lòng ghi rõ mối quan hệ với bệnh nhân: ........
                      <span
                        class="cs-content ml-1"
                        v-html="form.legal_guardian_name"
                      ></span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-6 p-0">
                <div class="cs-flex cs-border-b">
                  <span
                    class="cs-text-md"
                  >Tôi đã giải thích đầy đủ về xét nghiệm, bao gồm rủi ro, lợi ích và lựa chọn thay thế cho bệnh nhân hoặc người giám hộ trên pháp lý và trả lời tất cả các câu hỏi của họ</span>
                  <span class="cs-content ml-1" v-html="form.counsellor_name"></span>
                </div>
                <div class="cs-flex flex-column align-items-start">
                  <div class="cs-text-sm" style="min-height:160px">
                    <br />
                  </div>
                  <div style="min-height:60px">
                    <span></span>
                  </div>
                  <div>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="container-border col-6 p-0 none-border-bottom">
              <div class="cs-flex">
                <span class="cs-text-md">Chữ ký bệnh nhân/Người giám hộ hợp pháp(*)</span>
              </div>
            </div>
            <div class="container-border col-6 p-0 none-border-left none-border-bottom">
              <div class="cs-flex">
                <span class="cs-text-md">Bác sĩ ký, ghi rõ họ tên</span>
                <span class="cs-content ml-1" v-html="form.counsellor_name"></span>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="container-border col-6 p-0 cs-border-r">
              <div class="cs-flex cs-border-b none-border-bottom">
                <span class="cs-text-md">Ngày:</span>
              </div>
            </div>
            <div class="container-border col-6 p-0 none-border-left">
              <div class="cs-flex cs-border-b  none-border-right none-border-bottom none-border-left">
                <span class="cs-text-md">Ngày:</span>
                <span class="cs-content ml-1" v-html="form.counsellor_name"></span>
              </div>
            </div>
          </div>
        </div>
      </b-row>

      <!-- PAGE 3 -->

      <!-- PAGE 3 -->
    </div>
  </div>
</template>
  
  <script>
import { mapState } from "vuex";
import HeaderForm from "@/views/PrintOrderForm/HeaderForm.vue";

const DEFAULT_FORM = {
  spine: null,
  birthday: null,
  blood_transfer: null,
  doctor_email: "",
  full_name: "",
  doctor: "",
  hospital: "",
  doctor_phone: "",
  factory_code: "",
  identity_card_number: "",
  medical_record_no: null,
  choice: null,
  sample1: null,
  sample2: null,
  sample3: null,
  gender: null,
  urgency: null,
  patient_phone: "",
  other_instruction: "",
  physician_name: "",
  contact_number: null,
  email: "",
  nsr_no: null,
  acc_name: "",
  acc_contact_no: null,
  acc_address: "",
  acc_email: "",
  paid_by: null,
  billed_to: "",
  bill_acc_no: null,
  bill_address: "",
  bill_phone: null,
  bill_email: "",
  sample_collection_date_time: null,
  sample_id: null,
  sample_type: null,
  fixative: null,
  other_fixative: "",
  body_site: "",
  official_stamp: "",
  solid_tumour_type: [],
  solid_tumour_stage: null,
  stage_relapse: "",
  haematological_tumour_type: [],
  additional_notes: "",
  test_selection: [],
  other_test_description: "",
  other_test_info: {},
  authorised_content: "",
  authorised_status: null,
  authorised_name: "",
  authorised_date: null,
  authorised_reject_reason: "",
  marketing_content: "",
  marketing_name: "",
  marketing_date: null,
  marketing_remark: "",
  patient_name: "",
  patient_id: null,
  completed_by: "",
  legal_guardian_name: "",
  legal_guardian_ic: "",
  legal_guardian_date: null,
  legal_guardian_signature: "",
  legal_guardian_confirm: "",
  counsellor_name: "",
  counsellor_name_2: "",
  counsellor_date: "",
  counsellor_signature: "",
  counsellor_confirm: "",
  list_primary: "",
  relapse: false,
  sample_requirement: [],
  shipping_instructions: "",
  office_content: "",
  // This line contains 18 causes, from top to bottom
  one_moron: null,
  two_nerve_muscles: null,
  three: null,
  four: null,
  five: null,
  six: null,
  seven: null,
  eight: null,
  nine: null,
  ten: null,
  eleventh: null,
  twelve: null,
  thirteen: null,
  fourteen: null,
  fifteen: null,
  sixteen: null,
  seventeen: null,
  eighteen: null,
  // This line contains 18 causes, from top to bottom
  // This line is for family members information
  members_name_one: "",
  birth_date_one: null,
  relationship_one: "",
  appearance_one: "",
  members_name_two: "",
  birth_date_two: null,
  relationship_two: "",
  appearance_two: ""
  // This line is for family members information
};

export default {
  name: "PrintOrderForm",
  components: {
    HeaderForm
  },
  data() {
    return {
      form: { ...DEFAULT_FORM },
      DEFAULT_FORM
    };
  },
  computed: {
    ...mapState({
      orders: state => state.orders
    })
  },
  watch: {
    $route() {
      if (this.$route.params.id) {
        this.handleMapData();
      }
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.handleMapData();
    }
  },
  methods: {
    handlePrint() {
      document.title = "Requisition-form";
      window.print();
    },
    handleMapData() {
      this.form = this.orders.requisitionFormV2 || { ...DEFAULT_FORM };
    },
    backToDetail() {
      document.title = "HODO Partner";
      this.$router.replace({
        name: "orderDetail",
        params: {
          id: this.orders?.viewDetail?.id
        },
        query: {
          step: 1
        }
      });
    },
    handleFormatDate(date) {
      if (!date) return "";
      return moment(date).format("DD-MM-YYYY");
    }
  }
};
</script>
  
  <style lang="scss" scoped>
.cs-container {
  position: relative;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;

  * {
    color: black;
    font-family: "Times New Roman", "Tinos", Times, serif !important;
  }

  #printOrderForm {
    margin: auto;
    // margin-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .bottom {
    margin-bottom: 10px;
  }

  h2 {
    // color: black;
    font-size: 28px;
  }
}

.order-list {
  list-style-type: disc;
}

.container-border {
  border: 1px solid #5c5c5c;
  padding: 0;
  width: 50%;
}

.part-4 {
  margin-top: 6px;
}

// .html2pdf__page-break {
//   page-break-before: always;
// }
.box {
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.left {
  margin-left: 1px;
}

.container-border-2 {
  border-bottom: 1px solid #5c5c5c;
  border-left: 1px solid #5c5c5c;
  border-right: 1px solid #5c5c5c;
  width: 100%;
}

.cs-title {
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 6px;
}

.cs-flex {
  display: flex;
  gap: 8px;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
}

.cs-flex-2 {
  display: flex;
  margin-left: 6px;
  box-sizing: border-box;
  align-items: center;
}

.cs-label {
  width: 150px;
}

.cs-border-b {
  border-bottom: 1px solid #5c5c5c;
}
.cs-border-t {
  border-top: 1px solid #5c5c5c;
}

.cs-border-l {
  border-left: 1px solid #5c5c5c;
}

.cs-border-r {
  border-right: 1px solid #5c5c5c;
}
.none-border-bottom {
  border-bottom: none;
}
.none-border-right {
  border-right: none;
}
.none-border-left {
  border-left: none;
}
.none-border-top {
  border-top: none;
}
.cs-width-full {
  width: 100% !important;
}

.cs-font-bold {
  font-weight: bold;
}

.cs-checkbox {
  pointer-events: none;
  display: flex;
  align-items: center;

  input {
    width: 18px;
    height: 18px;
  }

  input[type="checkbox"] {
    accent-color: #20419b;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-left: 6px;
  }
}

.cs-content {
  flex: 1;
}

.min-checkbox {
  min-width: 120px;
}
.box-content {
  display: block;
  padding: 6px;
  min-height: 120px;
}
.html2pdf__page-break {
  page-break-before: always;
}

.cs-padding {
  padding: 6px;
}

.cs-border {
  border: 1px solid #5c5c5c;
}

.content-none {
  width: 100%;
  border-bottom: 2px dashed;
}

.cs-text-sm {
  font-size: 20px;
  font-weight: bold;
  background-color: #0569c5;
  color: #fff;
}

.cs-text-md {
  font-size: 16px;
}

.cs-list {
  display: flex;
  gap: 12px;
}

.cs-ol {
  li {
    position: relative;
    padding-left: 10px;
    margin-left: 15px;
  }

  li:before {
    position: absolute;
    left: -2px;
  }
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 20px;
  color: black;
  font-size: 40px;
  cursor: pointer;
}

.cs-img {
  width: 300px;
}

.title-color {
  color: #0569c5;
  font-size: 36px !important;
  text-align: center;
}

@media print {
  body,
  html {
    height: 100vh;
    background-color: #fff !important;
  }

  .hidden-print {
    border: none;
    outline: none;
    background: transparent;
  }

  #BrowserPrintDefaults {
    display: none;
  }

  #printOrderForm {
    max-width: 1000px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .html2pdf__page-break {
    page-break-before: always;
  }

  @page {
    margin: 0;
  }
}
</style>